<template>
  <button
    class="
      btn btn-light
      card
      d-flex
      flex-row
      justify-content-between
      align-items-center
      px-3
      py-2
      text-dark
    "
  >
    <div class="d-flex flex-column align-items-start">
      <div>
        <strong>{{ title }}</strong>
      </div>
      <div>{{ description }}</div>
    </div>
    <div><i class="bi" :class="'bi-' + icon"></i></div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "Card",
  props: {
    title: { type: String },
    description: { type: String },
    icon: { type: String },
  },
});
</script>