<template>
  <button class="btn btn-light card px-3 py-2 text-dark">
    <div><i class="bi" :class="'bi-' + icon"></i></div>
    <div>{{ description }}</div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ShortcutCard",
  props: {
    description: { type: String },
    icon: { type: String },
  },
});
</script>