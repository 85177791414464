
import { defineComponent } from "vue";
import ShortcutCard from "@/components/ShortcutCard.vue";
import Card from "@/components/Card.vue";

export default defineComponent({
  name: "Home",
  components: {
    ShortcutCard,
    Card,
  },
});
