
import { defineComponent } from "vue";
export default defineComponent({
  name: "Card",
  props: {
    title: { type: String },
    description: { type: String },
    icon: { type: String },
  },
});
