<template>
  <main class="flex-fill">
    <div class="summary px-3 pb-3 text-light">
      <!-- Summary -->
      <div class="mb-2"><strong>Resumo</strong></div>
      <div class="d-flex justify-content-between mb-2">
        <span>Itens na lista de compras</span>
        <span>7 item(s)</span>
      </div>
      <div class="d-flex justify-content-between mb-2">
        <span>Próximo boleto</span>
        <span>em 25/08/2021 (6 dias)</span>
      </div>

      <!-- List of card shortcuts -->
      <div class="d-flex mt-3 mb-2">
        <ShortcutCard
          class="me-2"
          :description="'Lista de Compras'"
          :icon="'cart3'"
        />
        <ShortcutCard
          class="me-2"
          :description="'Tarefas'"
          :icon="'clipboard-check'"
        />
        <ShortcutCard :description="'Contas'" :icon="'cash-coin'" />
      </div>
    </div>

    <!-- Home content -->
    <div class="d-flex flex-column p-3">
      <h4 class="mb-3">Atalhos</h4>
      <Card
        class="mb-2"
        :title="'Ver a lista de compras'"
        :description="'Lista de compras'"
        :icon="'cart3'"
      />
      <Card
        class="mb-2"
        :title="'Adicionar um item na lista de compras'"
        :description="'Lista de compras'"
        :icon="'cart3'"
      />
      <Card
        class="mb-2"
        :title="'Adicionar uma tarefa'"
        :description="'Tarefas'"
        :icon="'clipboard-check'"
      />
      <Card
        class="mb-2"
        :title="'Ver contas a pagar'"
        :description="'Contas'"
        :icon="'cash-coin'"
      />
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ShortcutCard from "@/components/ShortcutCard.vue";
import Card from "@/components/Card.vue";

export default defineComponent({
  name: "Home",
  components: {
    ShortcutCard,
    Card,
  },
});
</script>

<style lang="scss" scoped>
div.summary {
  background-color: #368bda;
}
</style>