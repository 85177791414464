<template>
  <RouterView />
</template>

<style lang="scss">
@import "styles/global";
@import "styles/fonts";

#app {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
