<template>
  <header class="d-flex justify-content-between p-3">
    <h3 class="mb-0">Boa noite!</h3>
    <h3 class="mb-0">
      <i class="bi bi-list"></i>
    </h3>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "Header",
});
</script>

<style lang="scss" scoped>
header {
  color: white;
  background-color: #368bda;
}
</style>